'use strict';

import Vue from 'vue';
import Router from 'vue-router';

import WelcomePage from '@/pages/Welcome.vue';
import SymbolsPage from '@/pages/Symbols.vue';
import BackgroundsPage from '@/pages/Backgrounds.vue';
import FontsPage from '@/pages/Fonts.vue';
import ColorsPage from '@/pages/Colors.vue';
import AboutPage from '@/pages/About.vue';
import EditorPage from '@/pages/Editor.vue';
import Error404Page from '@/pages/Error404.vue';

// import Changelog from '@/pages/Changelog.vue';
import RouterView from '@/components/routerview.vue';

Vue.use(Router);

import { loadLanguage, DEFAULT_LOCALE, langs } from '@/plugins/i18n';

const langsArray = langs.map((lang) => lang.code);

const router = new Router({
    mode: 'history',
    routes: [
    {
        path: "/:lang?/",
        component: RouterView,
        beforeEnter(to, from, next) {
            const newPath = to.path.replace(/\/\//g, '/');
            if (newPath !== to.path) {
                next(newPath);
                return
            }
            const lang = to.params.lang;
            if (lang && !langsArray.includes(lang)) {
                const newPathWithoutLang = newPath.slice(1 + lang.length); // Remove /en
                return next(`${DEFAULT_LOCALE}/${newPathWithoutLang}`);
            }
            loadLanguage(lang);
            return next();
        },
        children: [
        {
            path: '/',
            name: 'welcome',
            component: WelcomePage,
        },
        {
            path: 'symbols',
            name: 'symbols',
            component: SymbolsPage,
        },
        { 
            path: 'symbols/:symbolId', 
            name: 'symbol',
            component: SymbolsPage
        },
        {
            path: 'backgrounds',
            name: 'backgrounds',
            component: BackgroundsPage,
        },
        { 
            path: 'backgrounds/:symbolId', 
            name: 'background',
            component: BackgroundsPage
        },
        {
            path: 'colors',
            name: 'colors',
            component: ColorsPage,
        },
        {
            path: 'fonts',
            name: 'fonts',
            component: FontsPage,
        },
        {
            path: 'about',
            name: 'about',
            component: AboutPage,
        },
        // {
        //     path: 'changelog',
        //     name: 'changelog',
        //     component: Changelog,
        // },
        {
            path: 'edit/:templateId?/:exportType(png|jpg|template|pdf)?',
            name: 'editor',
            component: EditorPage,
        },
        {
            path: 'edit/:exportType(png|jpg|template|pdf)?',
            name: 'editorNew',
            component: EditorPage,
        },
        {
            path: '*',
            component: Error404Page
        }]
    }],
});

// router.beforeEach((to, from, next) => {
//     const newPath = to.path.replace(/\/\//g, '/');
//     if (newPath !== to.path) {
//         next(newPath);
//         return;
//     }
//     next();
// });

export default router;
