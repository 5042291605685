<template>
    <b-dropdown id='export-menu' v-if='valid' :triggers="['hover']" aria-role="list" style='text-align: left'>
        <template #trigger>
            <b-button
                label="Export"
                icon-right="menu-down"
                icon-left="download" />
        </template>
        <b-dropdown-item aria-role="listitem" has-link>
            <router-link id='export-png-button' :to="$router.currentRoute.path + '/png'">{{ $t('EDIT.EXPORT.PNG')}}</router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" has-link>
            <router-link id='export-jpg-button' :to="$router.currentRoute.path + '/jpg'">{{ $t('EDIT.EXPORT.JPG')}}</router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" has-link>
            <router-link id='export-pdf-button' :to="$router.currentRoute.path + '/pdf'">
            {{ $t('EDIT.EXPORT.PDF')}}
            <b-tag type="is-primary" size="is-small">{{ $t('BUTTONS.NEW') }}</b-tag>
            </router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" has-link v-if='advanced'>
            <router-link id='export-template-button' :to="$router.currentRoute.path + '/template'"> {{ $t('EDIT.EXPORT.TEMPLATE')}}</router-link>
        </b-dropdown-item>
    </b-dropdown>
</template>


<script>

export default {
	name: 'export-menu',
	methods: {
		onExport(format) {
			this.$emit('export', format);
		},
	},
    computed: {
        valid: function() {
            return this.$router.currentRoute.params.exportType === undefined;
        }
    },
	props: {
		advanced: Boolean,
	},
	data: () => ({
	})
}
</script>
