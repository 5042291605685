<template>
    <div class='container'>
        <p style='padding: 10px 0px'>
            
        <div class='row' style='margin:auto'>

            <b-tabs type="is-toggle" expanded multiline class='sticky-tabs-page'>
                <b-tab-item  v-for='tag, index in availableTags' :label="tag == '' ? $t('local.tags.all') : $t('local.tags.' + tag)" :key='index'>
                <div v-if='debug'>
                    <button @click='downloadJson'>Télécharger</button>
                    <a id='download'/>
                </div>
                    <div class='container is-flex is-flex-wrap-wrap is-justify-content-space-around'>
                        <div class="gallery-card card box is-flex is-flex-direction-column is-justify-content-space-between"
                            style='width:150px;margin:5px'
                            v-for="element, index in elementsByTag[tag]" :key="'gallery-' + index"
                            @click='openModal(element)'
                            :data-symbol-preview="element.id">

                            <div class='card-image' :data-badge="element['isNew'] ? $t('BUTTONS.NEW') : ''">
                                <figure class="image contain-image">
                                    <img :src="`/static/${path}/${element.preview}`" :alt="element.description"/>
                                </figure>
                            </div>
                            <div class="card-content">  
                                {{ element.localLabel }}
                            </div>
                        </div>
                    </div>
                </b-tab-item>
            </b-tabs>
        </div>

        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-modal v-if="selectedElement && !isLoading" v-model="isModalActive" :width="640" scroll="keep" :on-cancel='handleCancelModal'>
            <div class="card" style='padding: 15px'>
                <div class="card-image" style='padding: 15px, position: relative'>
                    <div class="checkerboard-background" :style="{ 
                        position: 'absolute',
                        top: (!isLoading ? (100 - selectedElement.displayedHeight)/2 : 0) + '%',
                        left: (!isLoading ? (100 - selectedElement.displayedWidth)/2 : 0) + '%',
                        width: (!isLoading ? selectedElement.displayedWidth : 100)  + '%',
                        height: (!isLoading ? selectedElement.displayedHeight : 100)  + '%' }">
                        <img v-if="coloredPreview.url" :src="coloredPreview.url"  :alt="selectedElement.description" style='width: 100%'>
                    </div>
                    <figure class="image is-4by3">
                        <img :src="`/static/${path}/${selectedElement.filename}`"  :alt="selectedElement.description"
                            :style='{ opacity: coloredPreview.url ? 0 : 1}'>
                    </figure>
                </div>
                <div class="card-content">
                    <div class="content">
                        {{ selectedElement.localLabel }}
                        <b-taglist>
                            <b-tag v-for="tag, index in tags" type="is-info" :key='index' >{{ $t('local.tags.' + tag) }}</b-tag>
                        </b-taglist>                
                        <b-taglist v-if='debug'>
                            <b-tag closable v-for="tag, index in availableTags" type="is-primary" :key='index'
                                @close='addTag(selectedElement.id, tag)'>{{ $t('local.tags.' + tag) }}</b-tag>
                        </b-taglist>
                    </div>
                    <hr/>
                    <palette-picker v-model='colors'/>
                </div>
                <footer class="card-footer">
                    <a class="card-footer-item" @click='downloadPng'>{{ $t('EDIT.EXPORT.PNG') }}</a>
                    <a :href="coloredPreview.url" class="card-footer-item" :download="selectedElement.id + '.svg'">{{ $t('EDIT.EXPORT.SVG') }}</a>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
import PalettePicker from '@/components/pickers/PalettePicker.vue';
import { loadSvg, colorSvgString } from '@/vida/components/svgutils.js';
import { DEFAULT_LOCALE } from '@/plugins/i18n';
import { exportComponentLabel } from '@/plugins/utils';
import Canvg from 'canvg';

export default {
    name: 'SvgColorableGallery',
    components: { PalettePicker },
    props: {
        elements: {
            type: Array,
            default: () => {[]}
        },
        path: {
            type: String,
            default: ''
        },
        routePrefix: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isModalActive: false,
        selectedElement: {},
        svgString: {},
        colors: {},
        isLoading: false,
        coloredPreview: {},
        canvas: undefined,
        choosenTags: [],
        debug: false
    }),
    mounted() {
        if(!this.$route.params.symbolId) return;
        
        const element = this.elementsByTag[''].find((element) => element.id == this.$route.params.symbolId);
        if (!element) this.$router.push({name: this.routePrefix + 's', params: { lang: this.$i18n.locale }});

        this.openModal(element);
    },
    methods: {
        openModal(element) {
            if (!this.$route.params.symbolId) {
                this.$router.push({ name: this.routePrefix, params: { symbolId: element.id, lang: this.$i18n.locale }});
            }

            if (this.isLoading) return;
            this.isModalActive = true;
            if (this.selectedElement.id === element.id) return;

            this.isLoading = true;
            this.selectedElement = element;

            const ratio = element.width / element.height;
            if (ratio < 4/3) { // portrait
                this.selectedElement.displayedHeight = 100;
                this.selectedElement.displayedWidth = 100*ratio*3/4;
            } else { // paysage
                this.selectedElement.displayedHeight = 100/ratio*4/3; 
                this.selectedElement.displayedWidth = 100;
            }
            const colors = {};
            for (let color of Object.keys(this.selectedElement.colors)) {
                colors[color] = this.selectedElement.colors[color];
            }

            loadSvg(`/static/${this.path}/${this.selectedElement.filename}`).then((svgString) => {
                this.isLoading = false;
                this.svgString = svgString;
                this.colors = colors;
            });
        },
        handleCancelModal() {
			this.$router.push({name: this.routePrefix + 's', params: { lang: this.$i18n.locale }});
        },
        downloadPng() {
            if (!this.coloredPreview) return;
            if(!this.canvas) this.canvas = window.document.createElement('canvas');
            
            const ctx = this.canvas.getContext('2d');
            const canvgElement = Canvg.fromString(ctx, this.coloredPreview.string);

            const widthRatio = 1000/this.selectedElement.width;
            const heightRatio = 1000/this.selectedElement.height;
            const ratio = Math.max(widthRatio, heightRatio);
            canvgElement.resize(this.selectedElement.width*ratio, this.selectedElement.height*ratio, 'xMidYMid meet');
            canvgElement.render().then(() => {
                const url = this.canvas.toDataURL({ mimeType: 'image/png', quality: 1 });
                const link = document.createElement('a');
                link.download = `${this.selectedElement.id}.png`;
                link.href = url;
                document.body.appendChild(link);
                link.click();

                // // cleanup temporary elements
                document.body.removeChild(link);
            });
        },
        addTag(elementId, tag) {
            for (let k=0; k<this.elements.length; ++k) {
                if (this.elements[k].id !== elementId) continue;
                const tags = (this.elements[k].tags ? this.elements[k].tags + ',' : '') + tag;
                this.elements[k].tags = tags;
                this.selectedElement.tags = tags;
            }
        },
        downloadJson() {
            const data = JSON.stringify(this.elementsByTag['']);
            const blob = new Blob( [data], {
                type: "application/json;charset=utf-8"
            });

            const url = URL.createObjectURL(blob);
            const downloadElem = document.getElementById('download');
            downloadElem.innerText = `Download ${length} pages scraped`;
            downloadElem.setAttribute('href', url);
            downloadElem.setAttribute('download', 'data.json');
            downloadElem.click();
            blob.destroy();
        }
    },
    computed: {
        elementsByTag() {
            const results = {};
            results[''] = [];
            this.elements.map((element) => {
                const augmentedElement = { ...element, localLabel: exportComponentLabel(element, this.$i18n.locale, DEFAULT_LOCALE)}
                results[''].push(augmentedElement);
                if (element['tags'] === '') return;
                const tags = element['tags'].split(',');
                for (const tag of tags) {
                    if (!results[tag]) results[tag] = [];
                    results[tag].push(augmentedElement);
                }
            });
            return results;
        },
        availableTags() {
            return Object.keys(this.elementsByTag);
        },
        tags() {
            if (!this.selectedElement || !this.selectedElement.tags) return [];
            return this.selectedElement.tags.split(',');
        }
    },
    watch: {
        // whenever question changes, this function will run
        colors: {
            handler: function (newColors) {
                if (!this.svgString) return;
                this.coloredPreview = colorSvgString(this.svgString, newColors);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.sticky-tabs-page nav {
	position: sticky;
	top: 30px;
	position: -webkit-sticky;
	z-index: 1;
	background: white;
	padding: 30px 0px 20px 0px;
}

.checkerboard-background {
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%);
    background-size: 25px 25px;
    background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
    background-repeat: repeat;
}
</style>