<template>
<div>
	<vida-navbar>
		<template v-slot:export>
			<vida-export-menu v-if='!isLoading' :advanced='advanced'/>
		</template>
	</vida-navbar>
		<section class='editor'>
			<b-loading :is-full-page="true" v-model="isLoading" class='primary-loading'></b-loading>
			<aside class='options-sidebar'>
						<!-- <div>
							<b-tooltip :label="$t('BUTTONS.UNDO')" style='padding: 0px 3px'>
								<b-button icon @click='$emit("undo")' size='is-small' icon-left="undo" :disabled='true'></b-button>
							</b-tooltip>
							<b-tooltip :label="$t('BUTTONS.REDO')" style='padding: 0px 3px'>
								<b-button icon @click='$emit("redo")' size='is-small' icon-left="redo" :disabled='true'></b-button>
							</b-tooltip>

							<b-button @click="onZoomOut" size="is-small" icon-left="folder-open" outlined></b-button>
							<b-button @click="onZoomOut" size="is-small" icon-left="content-save" outlined></b-button>
							<vida-export-menu :advanced='false'/>

						</div> -->

				<nav class='navbar is-spaced'>
					<div class="navbar-brand">
						<a class='navbar-item' role="button" @click='vida.selectComponent(cacheDocument.id)'>
							<b-icon class='left-icon' icon="file-document"></b-icon>
							{{ $t('EDIT.COMPONENTS.DOCUMENT') }}
						</a>
						<b-dropdown append-to-body aria-role="list" :max-height="500" scrollable trap-focus v-if='!isLoading'>
							<template #trigger>
								<a
									class="navbar-item"
									role="button">
									<b-icon  class='left-icon' icon="format-title"></b-icon>
									<span>{{ $tc('EDIT.COMPONENTS.TEXTS', Object.keys(cacheTexts).length) }}</span>
									<b-icon icon="menu-down"></b-icon>
								</a>
							</template>
							<b-dropdown-item v-for="(preview, key, index) in cacheTexts" :key='index' @click='vida.selectComponent(key)' aria-role="listitem">{{preview}}</b-dropdown-item>
							<hr class="dropdown-divider" aria-role="menuitem">
							<b-dropdown-item @click='createComponent("text")' aria-role="listitem" id='new-text-button'>
								<b-icon  class='left-icon' icon="plus-circle-outline" size="is-small"></b-icon>
								{{ $t('BUTTONS.NEW') }}
							</b-dropdown-item>
						</b-dropdown>

						<b-dropdown append-to-body aria-role="list" :max-height="500" scrollable trap-focus close-on-click v-if='!isLoading'> 
							<template #trigger>
								<a
									class="navbar-item"
									role="button">
									<b-icon icon="file-image"></b-icon>
									<span>{{ $tc('EDIT.COMPONENTS.SVGS', Object.keys(cacheSvg).length) }}</span>
									<b-icon icon="menu-down"></b-icon>
								</a>
							</template>

							<b-dropdown-item aria-role="list-item" paddingless v-for='(preview, key, index) in cacheSvg' :key='index' @click='vida.selectComponent(key)'>						
								<figure class="image contain-image">
									<img :src="preview" :alt="key"/>
								</figure>
							</b-dropdown-item>
							<hr class="dropdown-divider" aria-role="menuitem">
							<b-dropdown-item @click='createComponent("svg")' aria-role="listitem"  id='new-symbol-button'>
								<b-icon icon="plus-circle-outline" size="is-small"></b-icon>
								{{ $t('BUTTONS.NEW') }}
							</b-dropdown-item>

						</b-dropdown>
					</div>
				</nav>
				<template v-if='!isLoading'>
					<vida-document-input-component v-if='selectedType === "background"' :document='cacheDocument' @update='updateDocument' :key='this.rerenderSelectedComponent'/>
					<vida-text-input-component v-else-if='selectedType === "text"' :width='cacheDocument.width' :height='cacheDocument.height' :options='selectedComponentOptions'
						@update='(opts) => updateComponent(selectedComponent, opts)' 
						@remove='() => removeComponent(selectedComponent)'
						@duplicate='() => duplicateComponent(selectedComponent)'
						:nbComponents='nbComponents'						
						:key='this.rerenderSelectedComponent'/>

					<vida-svg-input-component v-else-if='selectedType === "svg"' :width='cacheDocument.width' :height='cacheDocument.height' :options='selectedComponentOptions'
						@update='(opts) => updateComponent(selectedComponent, opts)'
						@remove='() => removeComponent(selectedComponent)'
						@duplicate='() => duplicateComponent(selectedComponent)'
						:nbComponents='nbComponents'
						:key='this.rerenderSelectedComponent'/>

				</template>
			</aside>
			<vida-whiteboard style='margin-left: 430px' id="whiteboard" :zoom="zoom" @zoom:in="onZoomIn" @zoom:out="onZoomOut" @zoom:fit="onZoomFit" />
		</section>
	</div>
</template>

<script>
import Vida from '@/vida/vida';
import VidaWhiteboard from '@/components/whiteboard.vue';
import VidaNavbar from '@/components/navbar.vue'

import { uriToJSON } from '@/plugins/utils.js';
import  uuidv4 from "uuid/v4";
import { pdfMakeFonts, fontsLoaded } from '@/plugins/font-loader.js';

import VidaDocumentInputComponent from '@/components/visual-input-components/document-input-component.vue';
import VidaTextInputComponent from '@/components/visual-input-components/text-input-component.vue';
import VidaSvgInputComponent from '@/components/visual-input-components/svg-input-component.vue';
import VidaExportMenu from '@/components/exportmenu.vue';

import { isMobile } from '@/plugins/is-mobile.js';
import pallet from '@/assets/local/data/colors.json';

export default {
	name: 'editor',
	metaInfo: function() {
        return ({
            title: `Édition - ${this.$localConfig.id}`,
            titleTemplate: '%s | Aktivisda.earth',
			meta: [
				{ vmid: 'description', name: 'description', content: `${this.templateDescription}. | Avec Aktivisda.`  },
				{ property: 'og:title', vmid: 'og:title', content: `${this.$localConfig.id} | Aktivisda` },
				{ property: 'og:type', vmid: 'og:type', content: 'website' },
				{ property: 'og:url', vmid: 'og:url', content: this.$localConfig.url },
				{ property: 'og:image', vmid: 'og:image', content: this.$localConfig.url + `/static/templates/${this.templatePreview}` },
				{ property: 'og:description', vmid: 'og:description', content: `${this.templateDescription}. | Avec Aktivisda.earth` },
				{ property: 'og:site_name', vmid: 'og:site_name', content: `${this.$localConfig.id} | Aktivisda.earth` },
			]				
        });
    },

	components: { VidaWhiteboard, VidaNavbar,
		VidaDocumentInputComponent, VidaTextInputComponent, VidaSvgInputComponent, VidaExportMenu
	},
	data: function(){
		return {
			isLoading: false,
			zoom: 1,
			templateId: this.$route.params.templateId,
			selectedComponent: undefined,
			selectedComponentOptions: {},
			rerenderSelectedComponent: 0,
			cacheDocument: {},
			cacheTexts: {},
			rerenderTextsList: 0,
			cacheSvg: {},
			rerenderSvgsList: 0,
			hasChanged: false
		}
	},
	computed: {
		advanced: function() {
			return false || this.$route.query.advanced == "true";
		},
		templateJson: function() {
			if (this.templateId === undefined) {
				return {
					version: "0.9.2",
					document: {
						format: "CANVAS_FORMAT_CUSTOM",
						id: uuidv4(),
						resolution: 96,
						width: 1950,
						height: 800,
						background: { },
					},
					"components": []
				}
			}
			const templateInfo = this.$templates.find((temp) => temp.id === this.templateId);
			if (!templateInfo)
				return undefined;
			const templateJson = require('@/assets/local/templates/' + templateInfo.filename);
			return templateJson;
		},
		templateDescription: function() {
			if (this.templateId === undefined) return '';
			return this.$templates.find((temp) => temp.id === this.templateId).description;
		},
		templatePreview: function() {
			if (this.templateId == undefined) return '';
			return this.$templates.find((temp) => temp.id === this.templateId).preview;
		},
		selectedType: function() {
			if (this.selectedComponent === undefined) return 'background';
			if (Object.keys(this.cacheTexts).indexOf(this.selectedComponent) != -1) return 'text';
			if (Object.keys(this.cacheSvg).indexOf(this.selectedComponent) != -1) return 'svg';
			return 'background';
		},
		nbComponents: function() {
			return Object.keys(this.cacheTexts).length + Object.keys(this.cacheSvg).length;
		}
	},
	methods: {
		textPreview(txt) {
			txt = txt.replaceAll('\n', ' ');
			if (txt.length === 0) return '...';
			if (txt.length < 20) return txt;
			return txt.slice(0, 20) + '...';
		},
		svgPreview(id) {
			const symbol = this.$symbols.find((s) => s.id === id);
			return `/static/symbols/${symbol.preview}`;
		},
		onZoomFit() {
			this.zoom = this.vida.zoom(null);
		},
		onZoomOut() {
			this.zoom /= 1.2;
			this.vida.zoom(this.zoom);
		},
		onZoomIn()  {
			this.zoom *= 1.2;
			this.vida.zoom(this.zoom);
		},
		handleResize() {
			this.vida.handleResize();
		},
		setup() {
			return new Promise((resolve) => {
				this.isLoading = true;

				this.hasChanged = false;
				this.vida = new Vida("whiteboard");
				if (!this.templateJson) {
					this.isLoading = false;
					this.$buefy.dialog.alert({
						title: 'Erreur',
						message: `Le modèle <i>${this.templateId}</i> n'est pas-reconnu. Vous pouvez sélectionner un autre modèle sur la page d'accueil.`,
						type: 'is-danger',
						hasIcon: true,
						icon: 'times-circle',
						iconPack: 'fa',
						ariaRole: 'alertdialog',
						ariaModal: true,
						confirmText: "Aller sur la page d'accueil",
						onConfirm: () => this.$router.push({ name: 'welcome' })
					});
					return;
				}
				fontsLoaded().then(() => {
					this.vida.loadJson(this.templateJson).then(() => {
						
						this.cacheDocument = this.vida.documentParams();
						for (const key of this.vida.componentsKeys()) {
							const options = this.vida.componentParams(key);
							if (options.type === 'text') {
								this.cacheTexts[key] = this.textPreview(options.text);
							} else if (options.type === 'svg') {
								this.cacheSvg[key] = this.svgPreview(options.symbol.id);
							}
						}
						++this.rerenderTextsList;
						++this.rerenderSvgsList;

						this.vida.onSelect = (id) => {
							this.selectedComponent = id;
							this.selectedComponentOptions = this.vida.componentParams(id);
							++this.rerenderSelectedComponent;
						}

						this.vida.onComponentUpdated = (id) => {
							if (this.selectedComponent !== undefined && this.selectedComponent === id) {
								this.selectedComponentOptions = this.vida.componentParams(id);
								++this.rerenderSelectedComponent;
							}
							this.updateCache(id);
						}

						this.onZoomFit();
						this.updateIfQuery(this.$router.currentRoute).then(() => {
							if (this.$route.params.exportType !== undefined) {
								this.exportVida(this.$route.params.exportType).then(() => {
									this.isLoading = false;
									this.$router.push({ name: 'editor', templateId: this.templateId, lang: this.lang });
									resolve();
								});
							} else {
								resolve();
								this.isLoading = false;
							}
						});
					}).catch((error) => {
						console.log(error);
						this.$buefy.dialog.alert({
							title: 'Erreur',
							message: `Erreur inattendue dans le chargement du modèle.`,
							type: 'is-danger',
							hasIcon: true,
							icon: 'times-circle',
							iconPack: 'fa',
							ariaRole: 'alertdialog',
							ariaModal: true,
							confirmText: "Aller sur la page d'accueil",
							onConfirm: () => this.$router.push({ name: 'welcome' })
						});
						console.error(error);
						this.isLoading = false;
						return;
					});
				});		
			});
		},
		updateDocument(options) {
			this.hasChanged = true;
			const rerender = options.background !== undefined;
			const fitCanvas = options.width !== undefined || options.height !== undefined;
			this.vida.updateDocument(options).then(() => {
				if (rerender) ++this.rerenderSelectedComponent;
				if (fitCanvas) this.onZoomFit();
			});
		},
		updateComponent(id, options) {
			this.hasChanged = true;
			const rerender = options.zIndex !== undefined;
			this.vida.updateComponent(id, options).then(() => {
				if (this.selectedComponent === id && rerender) {
					this.selectedComponentOptions = this.vida.componentParams(this.selectedComponent);
					++this.rerenderSelectedComponent;
				}
				this.updateCache(id);
			});
		},
		removeComponent(id) {
			this.hasChanged = true;
			this.vida.removeComponent(id);
			if (this.cacheSvg[id]) {
				delete this.cacheSvg[id];
				++this.rerenderSvgsList;
			} else if (this.cacheTexts[id]) {
				delete this.cacheTexts[id];
				++this.rerenderTextsList;
			}
			if (this.selectedComponent === id) {
				this.selectedComponent = undefined;
			}
		},
		duplicateComponent(id) {
			this.hasChanged = true;
			const options = this.vida.componentParams(id);
			options.id = uuidv4();
			options.position.x *= 1.05
			options.position.y *= 1.05
			this.vida.createComponent(options).then(() => {
				if (options.type === 'text') {
					this.cacheTexts[options.id] = this.textPreview(options.text);
				} else if (options.type === 'svg') {
					this.cacheSvg[options.id] = this.svgPreview(options.symbol.id);
				}
				this.vida.selectComponent(options.id);
			});
		},
		createComponent(type) {
			this.hasChanged = true;
			let options;
			// todo move elsewhere
			switch (type) {
				case 'svg': {
					const symbol = this.$symbols[Math.floor(Math.random()*this.$symbols.length)];
					options = {
						type: "svg",
						symbol: {
							id: symbol.id,
							colors: symbol.colors, // todo random colors
						},
						scale: 20,
						position: { x: 0.5, y: 0.5 },
						angle: 0
					}
					break;
				}
				case 'text': {
					const font = this.$fonts[Math.floor(Math.random()*this.fonts.length)]
					options = {
						type: "text",
						color: pallet[Math.floor(Math.random()*pallet.length)].html,
						justification: "left",
						size: Math.floor(20 + Math.random()*180),
						font: font.fontName,
						text: this.$t('TEXTS.DEFAULT_TEXT'),
						position: { x: 0.5, y: 0.5 },
						angle: 0,
						background: {
							color: pallet[Math.floor(Math.random()*pallet.length)].html,
							padding: 5,
							enabled: Math.random() < 0.3
						}
					};
					break;
				}
			}
			options.id = uuidv4();
			this.vida.createComponent(options).then(() => {
				if (options.type === 'text') {
					this.cacheTexts[options.id] = this.textPreview(options.text);
				} else if (options.type === 'svg') {
					this.cacheSvg[options.id] = this.svgPreview(options.symbol.id);
				}
				this.vida.selectComponent(options.id);
			});
		},
		updateCache(id) {
			const options = this.vida.componentParams(id);
			if (options.type === 'text') {
				const preview = this.textPreview(options.text);
				if (preview !== this.cacheTexts[id]) {
					this.cacheTexts[id] = preview;
					++this.rerenderTextsList;
				}
			} else if (options.type === 'svg') {
				const preview = this.svgPreview(options.symbol.id);
				if (preview !== this.cacheSvg[id]) {
					this.cacheSvg[id] = preview;
					++this.rerenderSvgsList;
				}
			}
		},
		exportVida(format) {
			return new Promise((resolve, reject) => {
				this.loading = true;
				switch (format) {
					case 'pdf':
						this.exportPdf().then(() => { this.loading = false; resolve(); });
						break;
					case 'png':	
						this.exportImage('png').then(() => { this.loading = false; resolve(); });
						break;
					case 'jpg':
						this.exportImage('jpeg').then(() => { this.loading = false; resolve(); });
						break;
					case 'template':
						this.exportTemplate().then(() => { this.loading = false; resolve(); });
						break;
					default:
						console.log('Export to ${format} is undefined');
						this.loading = false;
						reject();
				}
			})
		},
		exportImage(mime) {
			return new Promise((resolve, reject) => {
				if (!this.vida) {
					reject();
					return;
				}

				const filename = 'aktivisda-' + this.templateId;
				const ext = mime;

				const canvas = document.createElement('canvas');
				canvas.id = 'temporary-canvas';
				const { width, height } = this.vida.documentParams();
				canvas.width = width;
				canvas.height = height;
				document.body.appendChild(canvas)
				
				const exportedVida = new Vida("temporary-canvas");
				exportedVida.loadJson(this.vida.toJson()).then(() => {

					// generate the image and download it
					// const ratio = window.devicePixelRatio || 1; // TODO
					const url = exportedVida.stage.toDataURL({ mimeType: 'image/' + mime, quality: 1 });
					const link = document.createElement('a');
					link.download = `${filename}.${ext}`;
					link.href = url;
					document.body.appendChild(link);
					link.click();

					// // cleanup temporary elements
					document.body.removeChild(link);
					document.body.removeChild(canvas);
					canvas.remove();
					exportedVida.destroy();
					resolve();
				});
			});
		},
		exportTemplate() {
			return new Promise((resolve) => {
				const str = JSON.stringify(this.vida.toJson(), null, 4);
				const bytes = new TextEncoder().encode(str);
				const blob = new Blob([bytes], {
					type: "application/json;charset=utf-8"
				});
				const url = window.URL.createObjectURL(blob);
				let link = document.createElement('a');
				link.download = this.templateId + '.json';
				link.href = url;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				link = null;
				resolve();
			});
		},
		exportPdf() {
			return new Promise((resolve) => {
				// Do no load library if useless
				const pdfMake = require("pdfmake/build/pdfmake");
				pdfMake.fonts = pdfMakeFonts;
				const pdfContent = this.vida.toPdf();
				if (pdfContent.status !== 'ok') {
					this.$buefy.snackbar.open({
						duration: 6000,
						message: this.$t('EDIT.EXPORT.PDF_ERROR'),
						type: 'is-danger',
						cancelText: this.$t('BUTTONS.CANCEL'),
						position: 'is-bottom',
						actionText: null
					});

				}

				const pdfDocGenerator = pdfMake.createPdf(pdfContent.pdfDescription, null, /*fonts*/);
				pdfDocGenerator.getDataUrl((url) => {
					const link = document.createElement('a');
					// Todo computed ?
					const filename = 'aktivisda-' + this.templateId;
					link.download = `${filename}.pdf`;
					link.href = url;
					document.body.appendChild(link);
					link.click();

					// // cleanup temporary elements
					document.body.removeChild(link);
					resolve();
				});
			});
		},
		raiseMobileConfirm() {
			this.$buefy.dialog.confirm({
				title: this.$i18n.t('EDIT.SMARTPHONE_WARNING.TITLE'),
				message: this.$i18n.t('EDIT.SMARTPHONE_WARNING.MESSAGE'),
				confirmText: this.$i18n.t('EDIT.SMARTPHONE_WARNING.CONFIRM_TEXT'),
				cancelText: this.$i18n.t('EDIT.SMARTPHONE_WARNING.CANCEL_TEXT'),
				type: 'is-info',
				hasIcon: true,
				onConfirm: () => this.setup(),
				onCancel: () => this.$router.push({ name: 'welcome' })
			});
		},
		updateIfQuery(query) {
			return new Promise((resolve) => {
				if (!query || !query.data) { resolve(); return }
				try {
					const options = uriToJSON(query.data);
					const document = options.document;
					if (document) this.vida.updateDocument(document);
					const components = options.components;
					if (components) {
						for (let k=0;k<components.length; ++k) {
							this.vida.updateComponent(components[k].id, components[k]);
						}
					}
					this.$router.replace({'query': {}}).catch(() => {});
					resolve();
				} catch (error) {
					// todo handle error
					console.log(query.data);
					console.log(error)
					this.$router.replace({'query': {}}).catch(() => {});
					resolve();
				}
			});
		}
	},
	mounted: function() {
		window.addEventListener('resize', this.handleResize)

		if (isMobile()) {
			this.raiseMobileConfirm();
		} else {
			this.setup().then(() => document.dispatchEvent(new Event("x-app-rendered")));
		}
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleResize)
		this.vida.destroy();
	},
	beforeRouteUpdate(to, from, next) {
		if (to.params.templateId !== from.params.templateId) {
			this.templateId = to.params.templateId;
			next(); // todo
			this.setup();
		} else {
			this.updateIfQuery(to.query).then(() => {
				if (to.params.exportType !== undefined) {
					this.exportVida(to.params.exportType).then(() => {
						next(from);
					});
				}
			})
		}
		next();
	},
	beforeRouteLeave(to, from, next) {
		if (!this.hasChanged) {
			next();
			return;
		}

		this.$buefy.dialog.confirm({
			title: this.$i18n.t('EDIT.UNSAVED_WORK.TITLE'),
			message: this.$i18n.t('EDIT.UNSAVED_WORK.MESSAGE'),
			confirmText: this.$i18n.t('EDIT.UNSAVED_WORK.QUIT_BUTTON'),
			cancelText: this.$i18n.t('EDIT.UNSAVED_WORK.STAY_BUTTON'),
			type: 'is-danger',
			hasIcon: true,
			onConfirm: () => next(),
			onCancel: () => next(false)
		});
	}
};
</script>

<style lang="scss">
.editor {
	min-height: calc(100vh - 100px);
}

.options-sidebar {
  height: 100%;
  width: 430px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color:white;
  overflow-x: hidden;
  padding-top: 70px;
}

.preview-card {
    width: 70px;
}

.dropdown-item figure {
	height: 50px;
}

.dropdown-item figure img {
	object-fit: contain;
	height: 100%;
}

.options-sidebar nav.navbar {
	border-bottom: 2px ridge gray;
	margin: 10px 27px 20px 35px;
	padding: 10px 7px 1px 7px;
}

.options-sidebar .navbar-item .left-icon:only-child {
	margin-right: 5px;
} 

.primary-loading {
	.loading-icon::after {
		border-left-color: var(--primary-color)!important;
		border-bottom-color: var(--primary-color)!important;
	}
}
</style>
