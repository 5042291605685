'use strict'

import AbstractVidaComponent from '@/vida/components/abstractvidacomponent.js'
import { colorSvgString, colorsChanged, loadSvg, makeHitformFunction } from '@/vida/components/svgutils.js';
import { computeLocation, computeLocationParams } from '@/vida/components/positionutils.js';

import symbols from '@/assets/local/data/symbols.json';
import Konva from 'konva';
import Canvg from 'canvg';

export default class SvgVidaComponent extends AbstractVidaComponent {

    constructor(vida, id) {
        super(vida, id);
        this.selected = false;
    }

    toJson() {
        if (!this.konvaElement) return { type: 'svg' };
        console.assert(Math.abs(this.konvaElement.scaleX() - this.konvaElement.scaleY()) < 0.001);
        
        return {
            id: this.id,
            type: "svg",
            symbol: {
                id: this.symbolId,
                colors: this.colors
            },
            scale: this.scale*this.konvaElement.scaleX(),
            position: {
                x: this.position.x,
                y: this.position.y
            },
            angle: this.angle,
            zIndex: this.konvaElement.zIndex(),
            filters:  this.filters ? this.filters :  ''
        }
    }
    
    toPdf() {
        if (!this.konvaElement) return { status: 'unsupported_option', content: {} };
        const { x, y } = computeLocation(this.position, this.documentSize);
        return {
            content: {
                svg: this.coloredString,
                absolutePosition: {
                    x: x - this.konvaElement.width()/2,
                    y: y - this.konvaElement.height()/2,
                },
                width: this.konvaElement.width(),
                height: this.konvaElement.height(),
            },
            status: 'ok'
        }
    }

    select() {
        if (!this.konvaElement) return;
        this.selected = true;
        if (!this.konvaTransformer) {
            this.konvaTransformer = new Konva.Transformer({ 
                resizeEnabled: true, 
                rotateEnabled: true,
                enabledAnchors: [
                    'top-left',
                    'top-right',
                    'bottom-left',
                    'bottom-right',
                ],
                keepRatio: true
            });
        }
        this.konvaTransformer.nodes([this.konvaElement]);
        this.vida.registerTransformer(this.konvaTransformer);
        this.konvaElement.setDraggable(true);
        this.vida.draw();
    }
    
    unselect() {
        this.selected = false;
        if (this.konvaElement) this.konvaElement.setDraggable(false);
        if (this.konvaTransformer) this.konvaTransformer.detach();
    }

    destroy() {
        if (this.konvaElement) this.konvaElement.destroy();
    }


    internalUpdate(options, document=null) {
        return new Promise((resolve, reject) => {
            if (this.inPromise) {
                resolve();
                return;
            }

            if (options.id !== undefined) this.id = options.id;
            if (options.filters !== undefined && options.filters !== this.filters) this.filters = options.filters;
            if (options.zIndex !== undefined && (this.zIndex === undefined || options.zIndex !== this.zIndex)) {
                this.zIndex = options.zIndex;
            }

            if (options.symbol !== undefined && (this.symbolId === undefined || this.symbolId != options.symbol.id)) {
                let symbolIndexOf =  symbols.map(e => e.id).indexOf(options.symbol.id);
                if (symbolIndexOf < 0) {
                    console.log(`Unknown symbol id ${options.symbol.id}`);
                    symbolIndexOf = Math.floor(Math.random()*symbols.length);
                    const symbol = symbols[symbolIndexOf];
                    options.id = symbol.id;
                    options.symbol = symbol;
                    this.internalUpdate(options).then(() => resolve());
                    return;
                }

                const symbolFilename = symbols[symbolIndexOf].filename
                this.hitform = symbols[symbolIndexOf].hitform; // maybe undefined
                const symbolUrl = '/static/symbols/' + symbolFilename;
                
                this.colors = undefined;
                this.inPromise = true;
                loadSvg(symbolUrl).then((svgString) => {
                    this.svgString = svgString;
                    this.symbolId = options.symbol.id;
                    this.inPromise = false;
                    this.originalSize = { width: symbols[symbolIndexOf].width, height: symbols[symbolIndexOf].height };
                    this.internalUpdate(options, document).then(() => resolve()).catch((err) => reject(err));
                }).catch((err) => reject(err));
                return;
            }
            
            if (options.symbol !== undefined && (!this.canvas || colorsChanged(this.colors, options.symbol.colors))) {
                this.recomputeScale = true;
                this.recomputePosition = true;
                this.colors = options.symbol.colors;
                console.assert(this.svgString);
                this.coloredString = colorSvgString(this.svgString, options.symbol.colors).string;
            }
            
            const documentDimensionChanged = !this.documentSize || document && (this.documentSize.width !== document.width || this.documentSize.height !== document.height);
            
            if (documentDimensionChanged || this.recomputeScale || options.scale !== undefined && (this.scale === undefined || this.scale !== options.scale)) {
                if (options.scale !== undefined) this.scale = options.scale;
                this.recomputeScale = false;
                this.recomputePosition = true;
                
                if (!this.documentSize) this.documentSize = {};
                if (document && document.width) this.documentSize.width = document.width;
                if (document && document.height) this.documentSize.height = document.height;
                
                const ratioWidth = this.documentSize.width / this.originalSize.width;
                const ratioHeight = this.documentSize.height / this.originalSize.height;
                const ratio = this.scale/100*Math.max(ratioWidth, ratioHeight);
                
                const imageWidth = ratio*this.originalSize.width;
                const imageHeight = ratio*this.originalSize.height;
                
                if(!this.canvas) this.canvas = window.document.createElement('canvas');
                const ctx = this.canvas.getContext('2d');
                this.canvgElement = Canvg.fromString(ctx, this.coloredString);
                
                
                this.canvgElement.resize(imageWidth, imageHeight, 'xMidYMid meet');
                this.inPromise = true;
                this.canvgElement.render().then(() => {
                    if (this.konvaElement) this.konvaElement.destroy();
                    this.konvaElement = new Konva.Image({ draggable: false, image: this.canvas });//this.canvas });
                    
                    this.konvaElement.on('click', () => {
                        this.vida.selectComponent(this.id);
                    });
                    this.konvaElement.on('dragend', () => {
                        this.adjustPosition();
                    });
                    this.konvaElement.on('transformend', () => {
                        this.adjustSizeAndAngle()
                    });
                                        
                    this.vida.registerComponent(this.konvaElement);
                    if (this.selected) {
                        this.unselect();
                        this.select();
                    }

                    this.konvaElement.setAttrs({
                        width: imageWidth,
                        height: imageHeight,
                        scaleX: 1,
                        scaleY: 1,
                        image: this.canvas,
                        zIndex: this.zIndex,
                        hitFunc: makeHitformFunction(this.hitform, ratio)
                    });

                    this.inPromise = false;
                    this.recomputeAngle = true;
                    this.internalUpdate(options, document).then(() => resolve()).catch((err) => reject(err));
                });
                return;
            }

            if (this.recomputeAngle || options.angle !== undefined && options.angle !== this.angle) {
                if (options.angle !== undefined) this.angle = options.angle;
                this.recomputeAngle = false;
                this.konvaElement.setRotation(this.angle);
            }
            
            const positionChanged = this.position === undefined || (options.position !== undefined && (
                (options.position.x !== undefined && this.position.x !== options.position.x) ||
                (options.position.y !== undefined && this.position.y !== options.position.y)));

            if (this.recomputePosition
                || positionChanged
                || documentDimensionChanged) {
                this.recomputePosition = false;
                if (!this.documentSize) this.documentSize = {};
                if (document && document.width) this.documentSize.width = document.width;
                if (document && document.height) this.documentSize.height = document.height;
                if (options.position) {
                    if (this.position === undefined) this.position = {};
                    if (options.position.x) this.position.x = options.position.x;
                    if (options.position.y) this.position.y = options.position.y;
                }

                const { x, y } = computeLocation(this.position, this.documentSize);
                this.konvaElement.setAttrs({
                    x, 
                    y,
                    offsetX: this.konvaElement.width()/2,
                    offsetY: this.konvaElement.height()/2,
                    zIndex: this.zIndex,
                });
                this.vida.draw();
            }

            if (options.zIndex && options.zIndex != this.konvaElement.zIndex()) {
                // https://konvajs.org/docs/groups_and_layers/zIndex.html#page-title
                // console.log('update zindex ', options.zIndex);
                this.zIndex = options.zIndex;   
                this.konvaElement.zIndex(this.zIndex);
                this.vida.draw();
            }
            resolve();
        });
    }

    adjustPosition() {
        if (!this.konvaElement) return;
        this.position = computeLocationParams({ x: this.konvaElement.x(), y: this.konvaElement.y() }, this.documentSize);
        this.vida.onComponentUpdated(this.id);
    }

    adjustSizeAndAngle() {
        if (!this.konvaElement) return;
        this.position = computeLocationParams({ x: this.konvaElement.x(), y: this.konvaElement.y() }, this.documentSize);
        this.angle = this.konvaElement.rotation();
        console.assert(Math.abs(this.konvaElement.scaleX() - this.konvaElement.scaleY()) < 0.001);
        if (this.konvaElement.scaleX() !== 1) this.update({ scale: this.scale*this.konvaElement.scaleX()}, this.vida.documentParams())
        this.vida.onComponentUpdated(this.id);
    }


}