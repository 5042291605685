'use strict';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
const all_langs = require('@/assets/langs.json');
const localConfig = require('@/assets/local/localconfig.json');

export const langs = all_langs.filter((lang) => localConfig['availableLangs'].indexOf(lang.code) >= 0);

Vue.use(VueI18n);

// load default locale
export const DEFAULT_LOCALE = 'en';
let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

const newLocale = langs.find(l => l.code === locale) ? locale : DEFAULT_LOCALE;

const loadedLanguages = [newLocale];
const messages = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${newLocale}.json`);
messages['local'] = require(/* webpackChunkName: "i18n-[request]" */`@/assets/local/i18n/${newLocale}.json`);

//=================================================================================================================

export const i18n = new VueI18n({
	locale: newLocale,
	fallbackLocale: DEFAULT_LOCALE,
	silentFallbackWarn: true,
	messages: { [newLocale]: messages }
});

//=================================================================================================================


export const loadLanguage = async lang => {
	// requested lang is already the current locale
	if (i18n.locale === lang) { return }
	
	// requested lang is not available
	const isLangAvailable = langs.find(l => l.code === lang);
	if (!isLangAvailable) { return }

	// load locale if needed
	if (!loadedLanguages.includes(lang)) {
		const messages = await import(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${lang}.json`);
		messages['local'] = await import(/* webpackChunkName: "i18n-[request]" */`@/assets/local/i18n/${lang}.json`);
		i18n.setLocaleMessage(lang, messages);
		loadedLanguages.push(lang);
	}

	// set locale globally
	i18n.locale = lang;
};

// load user's preferred language
// TODO voir ce qu'on en fait
// loadLanguage(window.navigator.language.split('-')[0]);
